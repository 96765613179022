import slider1 from "../images/asseenon1.png";
import slider2 from "../images/asseenon2.png";
import slider3 from "../images/asseenon3.png";
import slider4 from "../images/asseenon4.png";
import slider5 from "../images/asseenon5.png";
import slider6 from "../images/asseenon6.png";
import slider7 from "../images/asseenon7.png";
import slider8 from "../images/asseenon8.png";

export const asSeenOn = [
  {
    image: slider1,
  },
  {
    image: slider2,
  },
  {
    image: slider3,
  },
  {
    image: slider4,
  },
  {
    image: slider5,
  },
  {
    image: slider6,
  },
  {
    image: slider7,
  },
  {
    image: slider8,
  },
];
