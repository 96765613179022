import React from "react";
import MedicalImageSlider from "../MedicalImageSlider/MedicalImageSlider";
import * as style from "./InactiveReview.module.scss";

interface IInactiveReview {
  firstName: string;
  lastName: string;
  credentials: string;
  translateAmount: number;
  setTranslateAmount: any;
  amountChanged: boolean;
  needsTransition: boolean;
  setNeedsTransition: any;
  direction: string;
  initialTranslate: number;
}

const InactiveReview = ({
  firstName,
  lastName,
  credentials,
  translateAmount,
  setTranslateAmount,
  amountChanged,
  needsTransition,
  setNeedsTransition,
  direction,
  initialTranslate,
}: IInactiveReview) => {
  return (
    <div>
      <div className={style.imageContainer}>
        {/* <img
          src={image}
          className={style.image}
          alt="Depiction of a medical specialist."
        /> */}
        <MedicalImageSlider
          translateAmount={translateAmount}
          setTranslateAmount={setTranslateAmount}
          amountChanged={amountChanged}
          needsTransition={needsTransition}
          setNeedsTransition={setNeedsTransition}
          direction={direction}
          initialTranslate={initialTranslate}
        />
      </div>

      <div className={style.nameContainer}>
        <div className={style.firstName}>{firstName}</div>
        <div className={style.lastName}>{lastName}</div>
      </div>

      <div className={style.credentials}>{credentials}</div>
    </div>
  );
};

export default InactiveReview;
