import React from "react";
import { asSeenOn } from "../../../constants/asSeenOn";
import InstagramSlider from "../../atoms/InstagramSlider/InstagramSlider";
import * as s from "./AsSeenOn.module.scss";

const AsSeenOn = () => {
  return (
    <div className={s.mainWrapper}>
      <div className={s.contentWrapper}>
        {" "}
        <p className={s.title}>As seen on</p>
      </div>

      <InstagramSlider sliderData={asSeenOn} hasHover={false} />
    </div>
  );
};

export default AsSeenOn;
