/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React from "react";
import * as style from "./MedicalReviewButton.module.scss";

interface IMedicalReviewButton {
  onNextClick?: any;
  onPrevClick?: any;
}

const MedicalReviewButton = ({
  onNextClick,
  onPrevClick,
}: IMedicalReviewButton) => {
  return (
    <div className={style.container}>
      <div className={style.prevBtn} onClick={onPrevClick}>
        <div className={style.btnContent}></div>
      </div>
      <div className={style.nextBtn} onClick={onNextClick}>
        <div className={style.btnContent}></div>
      </div>
    </div>
  );
};

export default MedicalReviewButton;
