import React from "react";
import * as s from "./Quote.module.scss";

const Quote = () => {
  return (
    <>
      <div className={s.container}>
        {/* <img src={troop} alt="" className={s.img}></img> */}
        <p className={s.span}>troop /trüp/ noun:</p>

        {/* <div className={s.textWrapper}> */}
        <p className={s.text}>a group of mushrooms</p>
        {/* </div> */}
      </div>
    </>
  );
};

export default Quote;
