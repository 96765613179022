import React, { useEffect, useState } from "react";
import * as s from "./Video.module.scss";
import arrow from "../../../images/arrowReadMore.svg";
import circle from "../../../images/videoCircle.svg";
import videoH from "../../../video/troopVideoHorizontal.mp4";
import videoV from "../../../video/troopVideoVertical.mp4";
import { Link } from "gatsby";

const Video = () => {
  const [video, setVideo] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined" && window.screen.width > 625) {
      setVideo(videoH);
    } else {
      setVideo(videoV);
    }
  }, []);

  return (
    <div className={s.container}>
      <div className={s.firstGroup}>
        <p className={s.paragraph}>Here comes your new favorite gummy</p>
        <p className={s.secondParagraph}>
          Where <i>delicious</i> meets the big benefits of functional mushrooms.
        </p>
        <Link to="/products" className={s.link}>
          View more <img src={arrow} alt="arrow"></img>
        </Link>
      </div>

      <div className={s.secondGroup}>
        <img src={circle} className={s.img} alt=""></img>
        <div className={s.videoWrapper}>
          <video
            controls
            preload="auto"
            loop
            autoPlay
            src={video}
            height="360px"
            width="100%"
            muted
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
