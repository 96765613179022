/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import { faqCards } from "../../../constants/faqCards";
import FAQCard from "../../atoms/FAQCard/FAQCard";
import * as s from "./FAQCardComponent.module.scss";

const cn = classNames.bind(s);

interface IFAQ {
  title: string;
  description: string;
}

const FAQCardComponent = () => {
  return (
    <div className={s.mainWrapper}>
      <p className={s.title}>Frequently asked questions</p>
      <p className={s.description}>Relax as we got you covered</p>
      <div className={s.cardWrapper}>
        {faqCards.map((faq: IFAQ) => (
          <FAQCard
            title={faq.title}
            description={faq.description}
            key={faq.title}
          />
        ))}
      </div>

      <p className={cn(s.description, s.moreQuestions)}>More questions?</p>
      <Link to="/blog/#faq" className={s.faq}>
        <p className={s.faq}>FAQ</p>
      </Link>
    </div>
  );
};

export default FAQCardComponent;
