import React from "react";
import * as s from "./InstagramSlider.module.scss";
import igLayer from "../../../images/igLayer.png";

interface ISliderData {
  image: string;
  link?: string;
}

const InstagramSlider = ({
  sliderData,
  hasHover,
}: {
  sliderData: ISliderData[];
  hasHover: boolean;
}) => {
  return (
    <section className={s.body}>
      <div className={s.slider}>
        <div className={s.slideTrack}>
          {sliderData.map((slide: ISliderData) => {
            return (
              <div key={slide.image} className={s.slide}>
                <a
                  className={s.slideImage}
                  href={slide.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className={s.slideImage} src={slide.image} alt="troop" />
                </a>

                {hasHover ? (
                  <a
                    className={s.hoverImage}
                    href={slide.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={igLayer} alt="hoverImage"></img>
                  </a>
                ) : null}
              </div>
            );
          })}

          {sliderData.map((slide: ISliderData) => {
            return (
              <div key={slide.image} className={s.slide}>
                <a
                  className={s.slideImage}
                  href={slide.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className={s.slideImage} src={slide.image} alt="troop" />
                </a>

                {hasHover ? (
                  <a
                    className={s.hoverImage}
                    href={slide.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={igLayer} alt="hoverImage"></img>
                  </a>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default InstagramSlider;
