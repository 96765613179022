// extracted by mini-css-extract-plugin
export var containerActive = "ActiveReview-module--container-active---rg2j";
export var credentials = "ActiveReview-module--credentials--5FMOB";
export var descriptionActive = "ActiveReview-module--description-active--WHzYt";
export var fade = "ActiveReview-module--fade--e+E5M";
export var fadeExit = "ActiveReview-module--fadeExit--ZxyVp";
export var fadeIn = "ActiveReview-module--fade-in--ZFs2k";
export var fadeOut = "ActiveReview-module--fade-out--foz5a";
export var firstName = "ActiveReview-module--first-name--f+MEg";
export var lastName = "ActiveReview-module--last-name--0pb3N";
export var nameContainer = "ActiveReview-module--name-container--T74XG";
export var sliderContainer = "ActiveReview-module--slider-container--ARa62";
export var textActiveContainer = "ActiveReview-module--text-active-container--t8FVu";
export var titleActive = "ActiveReview-module--title-active--p5ijA";