import React from "react";
import jelly from "../../../images/jellyImg.webp";
import * as s from "./AboutUsLink.module.scss";
import arrow from "../../../images/arrowReadMore.svg";
import IconWrapper from "../../molecules/IconWrapper/IconWrapper";
import { Link } from "gatsby";

const AboutUsLink = () => {
  return (
    <div className={s.container}>
      <img src={jelly} className={s.jellyImg} alt=""></img>
      <div className={s.textContainer}>
        <p className={s.paragraph}>Consciously Crafted</p>
        <p className={s.secondParagraph}>
          We studied ingredients and worked tirelessly to create a formula that
          is not only tasty, but effective.
        </p>
        <Link to="/products" className={s.link}>
          Learn More <img src={arrow} alt="arrow"></img>
        </Link>

        <IconWrapper />
      </div>
    </div>
  );
};

export default AboutUsLink;
