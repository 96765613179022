/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { medicalReviewData } from "../../../constants/medicalReviews";
import { useWindowSize } from "../../../hooks";
import * as styles from "./MedicalImageSlider.module.scss";

interface IMedicalImageSlider {
  needsTransition: boolean;
  setNeedsTransition: any;
  direction: string;
  translateAmount: number;
  setTranslateAmount: any;
  amountChanged: boolean;
  isActive?: boolean;
  initialTranslate?: number;
}

const MedicalImageSlider = ({
  needsTransition,
  setNeedsTransition,
  direction,
  translateAmount,
  setTranslateAmount,
  amountChanged,
  isActive,
  initialTranslate,
}: IMedicalImageSlider) => {
  const [shuffleData, setShuffleData] = useState(medicalReviewData);

  const windowDimensions = useWindowSize();

  const transLateVal = () => {
    if (!amountChanged) {
      return initialTranslate || 0;
    }

    return -translateAmount * 100;
  };

  const additionalSliderStyles = () => {
    let width;
    let containerWidth;
    if (isActive) {
      //width = windowDimensions.width < 768 ? "85vw" : "430px";

      if (windowDimensions.width < 768) {
        width = "85vw";
        containerWidth = `calc(85vw * ${medicalReviewData.length})`;
      } else if (windowDimensions.width < 825) {
        width = "70vw";
        containerWidth = `calc(70vw * ${medicalReviewData.length})`;
      } else {
        width = "430px";
        containerWidth = `calc(430px * ${medicalReviewData.length})`;
      }

      // containerWidth =
      //   windowDimensions.width < 825
      //     ? `calc(85vw * ${medicalReviewData.length})`
      //     : `calc(430px * ${medicalReviewData.length})`;
      return {
        container: {
          width: width,
          height: width,
        },
        slides: {
          width: containerWidth,
          transform: `translateX(-${width})`,
          // width: "calc(430px * 6)",
          //transform: "translateX(-430px)"
        },
      };
    }
    containerWidth =
      windowDimensions.width < 825
        ? `calc(100% * ${medicalReviewData.length})`
        : `calc(287px * ${medicalReviewData.length})`;

    return {
      container: {
        width: "287px",
        height: "287px",
      },
      slides: {
        width: containerWidth,
        // width: "calc(287px * 6)",
        transform: "translateX(-287px)",
      },
    };
  };

  const sliderStyle = () => {
    if (needsTransition) {
      return {
        transform: `translateX(${transLateVal()}%)`,
        transition: "transform 0.3s ease-in-out",
      };
    }
    return {
      transform: `translateX(${transLateVal()}%)`,
    };
  };

  const transitionEnd = (e: any) => {
    e.persist();
    handleSliderTranslateEnd();
  };

  const handleSliderTranslateEnd = () => {
    switch (direction) {
      case "next":
        setShuffleData([...shuffleData, ...shuffleData.slice(0, 1)].slice(-6));
        setTranslateAmount(translateAmount - 1);
        break;
      case "prev":
        setShuffleData([...shuffleData.slice(-1), ...shuffleData].slice(0, 6));
        setTranslateAmount(translateAmount + 1);

        break;
      default:
        break;
    }
    setNeedsTransition(false);
  };

  return (
    <div
      className={styles.container}
      style={additionalSliderStyles().container}
    >
      <div
        className={styles.slidesWrapper}
        style={sliderStyle()}
        onTransitionEnd={(e) => {
          transitionEnd(e);
        }}
      >
        <div className={styles.slides} style={additionalSliderStyles().slides}>
          {shuffleData.map((item: any, index: number) => (
            <div
              className={styles.slide}
              style={{ backgroundImage: `url(${item.image})` }}
              key={item.image + Math.random()}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MedicalImageSlider;
