/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React from "react";
import gif from "../../../images/Troop-Supertroop-GIF_1.gif";
import * as s from "./DiscoverTroop.module.scss";
import arrow from "../../../images/arrowReadMore.svg";

const DiscoverTroop = () => {
  return (
    <div className={s.mainWrapper}>
      <div className={s.leftWrapper}>
        <p className={s.title}>Discover Troop</p>
        <h1 className={s.subtitle}>let&apos;s get started</h1>
        <p className={s.description}>Like what you see?</p>
        {/* <p className={cn(s.description, s.description1)}>
          Then check out our products.
        </p> */}
        <a className={s.button} href="/products">
          Explore all <img src={arrow} alt="arrow"></img>
        </a>
      </div>
      <div className={s.rightWrapper}>
        <img src={gif} alt="gif"></img>
      </div>
    </div>
  );
};

export default DiscoverTroop;
