/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import img from "../../../images/MiracleMushrooms0.png";
import * as s from "./ShroomsHP.module.scss";
import arrow from "../../../images/arrowReadMore.svg";
import { Link } from "gatsby";

interface IProps {
  title: string;
  description: string;
}

const ShroomsHP = ({ title, description }: IProps) => {
  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.first}>
          <p className={s.paragraph}>{title}</p>
          <p className={s.secondParagraph}>{description}</p>
          <Link to="/meet-your-mushrooms" className={s.link}>
            Meet your mushrooms <img src={arrow} alt="arrow"></img>
          </Link>
        </div>
      </div>

      <div className={s.second}>
        <img src={img} className={s.img} alt=""></img>
      </div>
    </div>
  );
};

export default ShroomsHP;
