import React, { useEffect, useState } from "react";
import { ProductsHome } from "../../organisms/ProductsHome/ProductsHome";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../../g/queries/get-user-info";
import { useDispatch } from "react-redux";
import { userConstants } from "../../../modules/auth/constants";
import HomePageLogo from "../../organisms/HomePageLogo/HomePageLogo";
import Quote from "../../organisms/Quote/Quote";
import Video from "../../organisms/Video/Video";
import AboutUsLink from "../../organisms/AboutUsLink/AboutUsLink";
import ShroomsHP from "../../organisms/ShroomsHP/ShroomsHP";
import InstagramSliderComponent from "../../molecules/InstagramSliderComponent/InstagramSliderComponent";
import AsSeenOn from "../../molecules/AsSeenOn/AsSeenOn";
import FAQCardComponent from "../../molecules/FAQCardComponent/FAQCardComponent";
import DiscoverTroop from "../../molecules/DiscoverTroop/DiscoverTroop";
import MedicalSlider from "../../organisms/MedicalSlider/MedicalSlider";

const HomePageLayout = () => {
  const [token, setToken] = useState<string | null>(null);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const description =
    "Mushrooms, but make it fun. Welcome to a whole new way to chew your mushrooms.";

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  }, []);

  const { data } = useQuery(GET_USER, {
    variables: {
      customerAccessToken: token,
    },
    onCompleted: ({ customer }) => {
      if (customer?.lastIncompleteCheckout?.id)
        localStorage.setItem(
          "checkoutId",
          customer?.lastIncompleteCheckout?.id
        );
    },
  });
  dispatch({
    type: userConstants.GETALL_SUCCESS,
    user: data?.customer,
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 5%",
        }}
      >
        <HomePageLogo />

        {loaded ? (
          <>
            <ProductsHome />

            <Quote />

            <Video />

            <AsSeenOn />

            <AboutUsLink />

            <MedicalSlider />

            <ShroomsHP
              title="New to Mushrooms? Allow us to make an introduction."
              description={description}
            />

            <InstagramSliderComponent />

            <FAQCardComponent />

            <DiscoverTroop />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export { HomePageLayout };
