import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import * as styles from "./ActiveReview.module.scss";
import { useTransition, animated, useSpring } from "react-spring";
import MedicalImageSlider from "../MedicalImageSlider/MedicalImageSlider";

interface IActiveReview {
  activeData: Record<string, any>;
  needsTransition: boolean;
  setNeedsTransition: any;
  direction: string;
  translateAmount: number;
  setTranslateAmount: any;
  amountChanged: boolean;
  initialTranslate?: number;
}

const ActiveReview = ({
  activeData,
  needsTransition,
  setNeedsTransition,
  direction,
  translateAmount,
  setTranslateAmount,
  amountChanged,
  initialTranslate,
}: IActiveReview) => {
  const cn = classNames.bind(styles);
  const fadeAnimation = useTransition(activeData, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
  });

  return (
    <div className={styles.containerActive}>
      {fadeAnimation((style, item) =>
        item ? (
          <animated.div
            style={style}
            className={cn(styles.textActiveContainer)}
          >
            <div className={styles.titleActive}>{item.title}</div>
            <div className={styles.descriptionActive}>{item.description}</div>

            <div className={styles.nameContainer}>
              <div className={styles.firstName}>{item.firstName}</div>
              <div className={styles.lastName}>{item.lastName}</div>
            </div>
            <div className={styles.credentials}>{item.credentials}</div>
          </animated.div>
        ) : (
          <></>
        )
      )}

      <div className={styles.sliderContainer}>
        <MedicalImageSlider
          translateAmount={translateAmount}
          setTranslateAmount={setTranslateAmount}
          amountChanged={amountChanged}
          needsTransition={needsTransition}
          setNeedsTransition={setNeedsTransition}
          direction={direction}
          isActive={true}
        />
      </div>
    </div>
  );
};

export default ActiveReview;
