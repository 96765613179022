import slider1 from "../images/inSlider1.png";
import slider2 from "../images/inSlider2.png";
import slider3 from "../images/inSlider3.png";
import slider4 from "../images/inSlider4.png";
import slider5 from "../images/inSlider5.png";
import slider6 from "../images/inSlider6.png";
import slider7 from "../images/inSlider7.png";
import slider8 from "../images/inSlider8.png";
import slider9 from "../images/inSlider9.png";

export const SliderData = [
  {
    image: slider1,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
  {
    image: slider2,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
  {
    image: slider3,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
  {
    image: slider4,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
  {
    image: slider5,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
  {
    image: slider6,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
  {
    image: slider7,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
  {
    image: slider8,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
  {
    image: slider9,
    link: "https://www.instagram.com/trytroop/?hl=en",
  },
];
