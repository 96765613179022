/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import MedicalReviewButtons from "../../atoms/MedicalReviewButtons/MedicalReviewButton";
import * as style from "./MedicalSlider.module.scss";
import { medicalReviewData } from "../../../constants/medicalReviews";
import InactiveReview from "../../molecules/MedicalReviewInactiveReview/InactiveReview";
import ActiveReview from "../../molecules/MedicalReviewActiveReview/ActiveReview";
import { useWindowSize } from "../../../hooks";

const MedicalSlider = () => {
  const DIRECTION_TYPE = {
    next: "next",
    prev: "prev",
  };

  const [data, setData] = useState([]) as any;
  const [needsTransition, setNeedsTransition] = useState(false);
  const [direction, setDirection] = useState("");
  // slider states
  const [translateAmount, setTranslateAmount] = useState(0);
  const [amountChanged, setAmountChanged] = useState(false);
  //
  const [translateAmount2, setTranslateAmount2] = useState(0);
  const [translateAmount3, setTranslateAmount3] = useState(0);
  // window dimensions
  const windowDimensions = useWindowSize();

  useEffect(() => {
    const newData = [] as any;
    medicalReviewData.map((item) => {
      if (!item.fake) newData.push(item);
    });
    setData(newData);
  }, []);
  const onButtonClick = (direction: string, newDataOrder: any) => {
    setData(newDataOrder);
    setNeedsTransition(true);
    setDirection(direction);
  };

  const nextSlide = () => {
    // shuffle data
    const newDataOrder = [...data, ...data.slice(0, 1)].slice(-3);

    onButtonClick(DIRECTION_TYPE.next, newDataOrder);
    const _current = translateAmount + 1;

    if (_current > data.length - 1) return;
    setTranslateAmount(_current);
    setTranslateAmount2(_current + 1);
    setTranslateAmount3(_current + 2);
    setAmountChanged(true);
  };

  const prevSlide = () => {
    const newDataOrder = [...data.slice(-1), ...data].slice(0, 3);

    onButtonClick(DIRECTION_TYPE.prev, newDataOrder);

    const _current = translateAmount - 1;
    setTranslateAmount(_current);
    setTranslateAmount2(_current + 1);
    setTranslateAmount3(_current + 2);
    setAmountChanged(true);
  };

  return (
    <div className={style.container}>
      <div className={style.containerTitle}>Medical Reviews</div>
      <div className={style.containerSubtitle}>
        Reviews from people who usually <br /> dont write reviews.
      </div>
      {data.length ? (
        <div className={style.medicalContainer}>
          <ActiveReview
            needsTransition={needsTransition}
            setNeedsTransition={setNeedsTransition}
            activeData={data[0]}
            direction={direction}
            translateAmount={translateAmount}
            setTranslateAmount={setTranslateAmount}
            amountChanged={amountChanged}
          />

          {windowDimensions.width >= 825 ? (
            <>
              <InactiveReview
                key={"medical-review-02"}
                credentials={data[1].credentials}
                firstName={data[1].firstName}
                lastName={data[1].lastName}
                translateAmount={translateAmount2}
                setTranslateAmount={setTranslateAmount2}
                amountChanged={amountChanged}
                needsTransition={needsTransition}
                setNeedsTransition={setNeedsTransition}
                direction={direction}
                initialTranslate={-100}
              />

              <InactiveReview
                key={"medical-review-03"}
                credentials={data[2].credentials}
                firstName={data[2].firstName}
                lastName={data[2].lastName}
                translateAmount={translateAmount3}
                setTranslateAmount={setTranslateAmount3}
                amountChanged={amountChanged}
                needsTransition={needsTransition}
                setNeedsTransition={setNeedsTransition}
                direction={direction}
                initialTranslate={-200}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className={style.buttonsContainer}>
        <MedicalReviewButtons onNextClick={nextSlide} onPrevClick={prevSlide} />
      </div>
    </div>
  );
};

export default MedicalSlider;
