export const faqCards = [
  {
    title: `Wait… These are mushrooms… They aren’t going to make me trip, right?`,
    description: `No way! These mushrooms do not contain psilocybin--
     the compound that makes magic mushrooms trippy.  Our mushrooms are intended ...`,
  },
  {
    title: `Are there any side effects?`,
    description: `While mushrooms are completely safe and nontoxic,
       we recommend consulting a physician before taking any supplements. `,
  },
  {
    title: `What is the best time to take my gummies?`,
    description: `Great question! We recommend taking the Lion’s Mane gummy in the morning, Reishi at night and the Super Troop as a midday pick me up. `,
  },
];
