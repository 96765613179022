/* eslint-disable jsx-a11y/click-events-have-key-events, 
jsx-a11y/no-noninteractive-element-interactions, 
jsx-a11y/no-static-element-interactions */
import { Link } from "gatsby";
import React from "react";
import * as s from "./FAQCard.module.scss";

interface IFAQ {
  title: string;
  description: string;
}

const FAQCard = ({ title, description }: IFAQ) => {
  return (
    <div className={s.mainWrapper}>
      <Link to="/blog/#faq" className={s.link}>
        <p className={s.title}>{title}</p>
        <p className={s.description}>{description}</p>
      </Link>
    </div>
  );
};

export default FAQCard;
