import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import * as s from "./HomePageLogo.module.scss";
import img from "../../../static/images/TroopProductWeb.jpeg";
import img1 from "../../../static/images/mobileHero.webp";

const HomePageLayout = () => {
  const handleClick = () => {
    navigate("/products");
  };

  const [image, setImage] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined" && window.screen.width > 768) {
      setImage(img);
    } else {
      setImage(img1);
    }
  }, []);

  return (
    <div className={s.container}>
      <img src={image} alt="" className={s.img}></img>
      <div className={s.textWrapper}>
        <p className={s.secondP}>
          chew your <span>mushrooms</span>
        </p>

        <p className={s.firstP}>Your daily mushroom gummy</p>

        <Link to="/products" className={s.button} onClick={() => handleClick()}>
          Shop All
        </Link>
      </div>
    </div>
  );
};

export default HomePageLayout;
