import React from "react";
import { SliderData } from "../../../constants/SliderData";
import InstagramSlider from "../../atoms/InstagramSlider/InstagramSlider";
import * as s from "./InstagramSliderComponent.module.scss";

const InstagramSliderComponent = () => {
  return (
    <div className={s.mainWrapper}>
      <div className={s.contentWrapper}>
        {" "}
        <p className={s.title}>join our community</p>
        <p className={s.link}>#chewyourmushrooms</p>
      </div>

      <InstagramSlider sliderData={SliderData} hasHover={true} />
    </div>
  );
};

export default InstagramSliderComponent;
