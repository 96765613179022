import robin from "../images/robinMiller.png";
import mascha from "../images/maschaDavis.png";
import tanya from "../images/tanyaJohnston.png";

export const medicalReviewData = [
  {
    index: -1,
    image: tanya,
    title: '"The most amazing thing happened',
    description:
      "I had been struggling with a fungal infection in my nose and it  started to wane after using the gummies. Needless to say, I love your gummies!” ",
    role: "dr.",
    firstName: "Tanya",
    lastName: "Johnston",
    credentials:
      "Board certified doctor in natural medicine, Ph.D and certificated plant based nutritionist",
    fake: true,
  },
  {
    index: 0,
    image: robin,
    title: '"The troop mushrooms taste so good and are great for your health!"',
    description: "",
    role: "dr.",
    firstName: "Robin",
    lastName: "Miller",
    credentials: "Internist and Integrative Medicine Specialist",
    fake: false,
  },
  {
    index: 1,
    image: mascha,
    title: '"Troop has knocked it out of the park!',
    description:
      "Their new line of  mushroom gummies is not only functional, but also delicious. They not only taste incredible, but their benefits are science-based. I've not only been recommending these already, but am personally a fan.\"",
    role: "",
    firstName: "Mascha",
    lastName: "Davis",
    credentials: "Registered Dietitian Nutritionist",
    fake: false,
  },

  {
    index: 2,
    image: tanya,
    title: '"The most amazing thing happened',
    description:
      "I had been struggling with a fungal infection in my nose and it  started to wane after using the gummies. Needless to say, I love your gummies!” ",
    role: "dr.",
    firstName: "Tanya",
    lastName: "Johnston",
    credentials:
      "Board certified doctor in natural medicine, Ph.D and certificated plant based nutritionist",
    fake: false,
  },
  {
    index: 3,
    image: robin,
    title: '"The troop mushrooms taste so good and are great for your health!"',
    description: "",
    role: "dr.",
    firstName: "Robin",
    lastName: "Miller",
    credentials: "Internist and Integrative Medicine Specialist",
    fake: true,
  },
  {
    index: 4,
    image: mascha,
    title: '"Troop has knocked it out of the park!',
    description:
      "Their new line of  mushroom gummies is not only functional, but also delicious. They not only taste incredible, but their benefits are science-based. I've not only been recommending these already, but am personally a fan.\"",
    role: "",
    firstName: "Mascha",
    lastName: "Davis",
    credentials: "Registered Dietitian Nutritionist",
    fake: true,
  },
];
